<template>
  <div>
    <section role="main" class="empty">
      <div class="container">
        <div class="row icon-tabs">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <router-link to="/executive" class="nav-link"
                ><img src="/img/icons/executives.png" alt="Executives" />
                Executives</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/company" class="nav-link"
                ><img src="/img/icons/companies.png" alt="Companies" />
                Companies</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/news" class="nav-link"
                ><img src="/img/icons/deals.png" alt="News" /> News</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/deals-transactions" class="nav-link active"
                ><img src="/img/icons/deals.png" alt="Deals & Transactions" />
                Deals & Transactions</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/listing" class="nav-link"
                ><img src="/img/book.png" style="height: 23px" />
                Listing</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/commentaries" class="nav-link"
                ><img src="/img/icons/commentaries.png" alt="Commentaries" />
                Commentaries</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/interesting-bites" class="nav-link"
                ><img
                  src="/img/icons/interesting.png"
                  alt="Interesting Bites"
                />
                Interesting Bites</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/open-positons-new" class="nav-link"
                ><img src="/img/icons/position.png" alt="Open Position" /> Open
                Positons</router-link
              >
            </li>
            <li class="">
              <a
                class="nav-link"
                style="
                  font-weight: bold !important;
                  margin-top: 8px;
                  font-size: 13px;
                  color: #1f3342;
                "
              >
                {{ count }}</a
              >
            </li>
          </ul>
          <div id="fourthmenu" class="tab-content mb-3">
            <div class="container px-0">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <!-- <form class="form-search form-inline"> -->
                  <input
                    type="text"
                    class="search-query"
                    style="border: 0px none; width: 100%"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                  />
                  <!-- </form> -->
                </div>
              </div>
              <hr class="mt-0 mb-2" />
            </div>
            <div class="row">
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Deal Type</label>
                <Multiselect
                  mode="single"
                  trackBy="topic_name"
                  label="topic_name"
                  valueProp="id"
                  v-model="searchObj.topic_id"
                  :options="topicList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchTopicName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Company</label>
                <Multiselect
                  mode="single"
                  trackBy="company_name"
                  label="company_name"
                  valueProp="id"
                  :disabled="id"
                  placeholder="Select"
                  v-on:change="geteComponydetail($event)"
                  v-model="searchObj.company_id"
                  :options="companyList"
                  searchable="true"
                  @search-change="searchCompanyName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Industry</label>
                <Multiselect
                  mode="single"
                  trackBy="industry_name"
                  label="industry_name"
                  valueProp="id"
                  v-model="searchObj.industry_id"
                  :options="industryList"
                  searchable="true"
                  placeholder="Select"
                  @change="closesub($event)"
                  @input="onIndustryChange"
                  @search-change="searchIndustryName($event)"
                />
              </div>
              <div v-if="isShowSubsector" class="col-lg-2 col-sm-4 col-6">
                <label class="label">Subsector</label>

                <Multiselect
                  mode="single"
                  trackBy="industry_name"
                  label="industry_name"
                  valueProp="id"
                  v-model="searchObj.subsector_id"
                  :options="subsectorList"
                  searchable="true"
                  placeholder="Select"
                />
              </div>
              <!-- <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Select Topic</label>
                <Multiselect
                  mode="single"
                  trackBy="topic_name"
                  label="topic_name"
                  valueProp="id"
                  v-model="searchObj.topic_id"
                  :options="topicList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchTopicName($event)"
                />
              </div> -->
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">From Date</label>

                <datepicker
                  v-model="searchObj.from_date"
                  inputFormat="dd/MM/yyyy"
                  placeholder="To Date"
                  class="form-control"
                  style="background-color: white !important; height: 35px"
                  :clearable="false"
                  :upperLimit="
                    searchObj.to_date ? searchObj.to_date : new Date()
                  "
                >
                </datepicker>
              </div>
              <div class="col-lg-2 col-sm-4 col-6" v-if="!isShowSubsector">
                <label class="label">To Date</label>
                <datepicker
                  v-model="searchObj.to_date"
                  inputFormat="dd/MM/yyyy"
                  class="form-control"
                  placeholder="To Date"
                  :clearable="false"
                  style="background-color: white !important; height: 35px"
                  :lowerLimit="searchObj.from_date ? searchObj.from_date : null"
                  :upperLimit="new Date()"
                >
                </datepicker>
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <!-- <label></label> -->
                <button
                  type="button"
                  style="margin-top: 23px; width: 50%"
                  v-on:click="getList(0)"
                  class="apply-btn mr-1"
                >
                  Apply
                </button>
                <button
                  v-on:click="resetFilter()"
                  type="button"
                  style="margin-top: 23px; width: 40%"
                  class="apply-btn"
                >
                  Reset
                </button>
                <!--  <div class="text-center"><a href="#" class="advance-search">Advance Search</a></div> -->
              </div>
              <div class="col-lg-2 col-sm-4 col-6" v-if="isShowSubsector">
                <label class="label">To Date</label>
                <datepicker
                  v-model="searchObj.to_date"
                  inputFormat="dd/MM/yyyy"
                  class="form-control"
                  placeholder="To Date"
                  :clearable="false"
                  style="background-color: white !important; height: 35px"
                  :lowerLimit="searchObj.from_date ? searchObj.from_date : null"
                  :upperLimit="new Date()"
                >
                </datepicker>
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Deal Stage</label>
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  v-model="searchObj.deal_stage_id"
                  :options="dealStageList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchDealStage($event)"
                />
              </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <label class="label">Location</label>
                  <Multiselect
                    mode="single"
                    trackBy="country_name"
                    label="country_name"
                    valueProp="id"
                    v-model="searchObj.country_id"
                    :options="countryList"
                    searchable="true"
                    placeholder="Select"
                    @search-change="searchCountryName($event)"
                  />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                  <label class="label"
                    >Company Type {{ searchObj.company_type }}
                  </label>
                  <Multiselect
                    mode="single"
                    trackBy="value"
                    label="value"
                    valueProp="id"
                    v-model="searchObj.company_type"
                    :options="company_types"
                    searchable="true"
                    placeholder="Select"
                  />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                  <label class="label">Region</label>
                  <Multiselect
                    mode="single"
                    trackBy="region_name"
                    label="region_name"
                    valueProp="id"
                    v-model="searchObj.region_id"
                    :options="regionList"
                    searchable="true"
                    placeholder="Select"
                    @search-change="searchRegionName($event)"
                  />
              </div>
            </div>
            <hr />
            <div v-for="(data, index) in list" :key="index">
              <div class="row mt-4">
                <div
                  class="col-lg-2 col-sm-2 text-center"
                  style="cursor: pointer"
                  v-on:click="
                    $router.push(
                      '/company-detail/' + data.primary_company.company_id
                    )
                  "
                >
                  <a>
                    <img
                      :title="
                        data.primary_company &&
                        data.primary_company.company_name
                          ? data.primary_company.company_name
                          : ''
                      "
                      :src="
                        data.primary_company &&
                        data.primary_company.company_logo
                          ? data.primary_company.company_logo
                          : '/img/user-pic.png'
                      "
                      alt=""
                      width="40"
                      height="40"
                    />
                  </a>
                  <br />
                  <a>
                    <country-flag
                      :country="data.country ? data.country.country_code : 'US'"
                    />
                  </a>
                  <br />
                  <span class="update-date"
                    >Published Date :
                    {{
                      $helperService.getFormatedDate(data.published_date)
                    }}</span
                  >
                </div>
                <div class="col-lg-8 col-sm-5 pb-3 txt-center">
                  <p class="clrb" style="padding: 0; margin: 0">
                    {{ data.headline }}
                  </p>
                  <div class="col-12 row">
                    <div class="col-3 mt-3 row">
                      <div
                        v-for="(data2, index2) in data.deals_mention_executives"
                        :key="index2"
                      >
                        <div v-if="index2 <= 4">
                          <img
                            v-if="
                              data2.gender == 'female' && !data2.executive_logo
                            "
                            style="
                              cursor: pointer;
                              border: 1px solid white; /*! padding: 0.1px; */
                              box-shadow: 18px;
                              box-shadow: 0px 0px 0.5px 0.5px GREY;
                            "
                            :title="data2.executive_name"
                            v-on:click="
                              $router.push(
                                '/executive-detail/' + data2.executives_id
                              )
                            "
                            :src="
                              data2.executive_logo
                                ? data2.executive_logo
                                : '/img/female.png'
                            "
                            width="50"
                            height="50"
                            :class="index2 == 0 ? 'sm-img' : 'sm-img minus8'"
                            :style="
                              index2 == 0 ? 'width: 31px;' : 'width: 37px;'
                            "
                          />
                          <img
                            v-if="
                              data2.gender == 'male' && !data2.executive_logo
                            "
                            style="
                              cursor: pointer;
                              border: 1px solid white; /*! padding: 0.1px; */
                              box-shadow: 18px;
                              box-shadow: 0px 0px 0.5px 0.5px GREY;
                            "
                            :title="data2.executive_name"
                            v-on:click="
                              $router.push(
                                '/executive-detail/' + data2.executives_id
                              )
                            "
                            :src="
                              data2.executive_logo
                                ? data2.executive_logo
                                : '/img/male.png'
                            "
                            width="50"
                            height="50"
                            :class="index2 == 0 ? 'sm-img' : 'sm-img minus8'"
                            :style="
                              index2 == 0 ? 'width: 31px;' : 'width: 37px;'
                            "
                          />
                          <img
                            v-if="data2.executive_logo || !data2.gender"
                            style="
                              cursor: pointer;
                              border: 1px solid white; /*! padding: 0.1px; */
                              box-shadow: 18px;
                              box-shadow: 0px 0px 0.5px 0.5px GREY;
                            "
                            :title="data2.executive_name"
                            v-on:click="
                              $router.push(
                                '/executive-detail/' + data2.executives_id
                              )
                            "
                            :src="
                              data2.executive_logo
                                ? data2.executive_logo
                                : '/img/male.png'
                            "
                            width="50"
                            height="50"
                            :class="index2 == 0 ? 'sm-img' : 'sm-img minus8'"
                            :style="
                              index2 == 0 ? 'width: 31px;' : 'width: 37px;'
                            "
                          />
                        </div>
                      </div>
                      <!-- sm-img minus8 -->
                    </div>
                    <div class="col-3 mt-3 row">
                      <div
                        v-for="(data2, index2) in data.deals_mention_company"
                        :key="index2"
                      >
                        <div v-if="index2 <= 4">
                          <img
                            style="
                              cursor: pointer;
                              border: 1px solid white; /*! padding: 0.1px; */
                              box-shadow: 18px;
                              box-shadow: 0px 0px 0.5px 0.5px GREY;
                            "
                            :title="data2.company_name"
                            v-on:click="
                              $router.push(
                                '/company-detail/' + data2.company_id
                              )
                            "
                            :src="data2.company_logo"
                            width="50"
                            height="50"
                            :class="index2 == 0 ? 'sm-img' : 'sm-img minus8'"
                            :style="
                              index2 == 0 ? 'width: 31px;' : 'width: 37px;'
                            "
                          />
                        </div>
                      </div>
                      <!-- sm-img minus8 -->
                    </div>
                    <div class="col-3 mt-3 row">
                      <div
                        v-for="(
                          data2, index2
                        ) in data.deals_invested_executives"
                        :key="index2"
                      >
                        <div v-if="index2 <= 4">
                          <img
                            v-if="
                              data2.gender == 'female' && !data2.executive_logo
                            "
                            style="
                              cursor: pointer;
                              border: 1px solid white; /*! padding: 0.1px; */
                              box-shadow: 18px;
                              box-shadow: 0px 0px 0.5px 0.5px GREY;
                            "
                            :title="data2.executive_name"
                            v-on:click="
                              $router.push(
                                '/executive-detail/' + data2.executives_id
                              )
                            "
                            :src="
                              data2.executive_logo
                                ? data2.executive_logo
                                : '/img/female.png'
                            "
                            width="50"
                            height="50"
                            :class="index2 == 0 ? 'sm-img' : 'sm-img minus8'"
                            :style="
                              index2 == 0 ? 'width: 31px;' : 'width: 37px;'
                            "
                          />
                          <img
                            v-if="
                              data2.gender == 'male' && !data2.executive_logo
                            "
                            style="
                              cursor: pointer;
                              border: 1px solid white; /*! padding: 0.1px; */
                              box-shadow: 18px;
                              box-shadow: 0px 0px 0.5px 0.5px GREY;
                            "
                            :title="data2.executive_name"
                            v-on:click="
                              $router.push(
                                '/executive-detail/' + data2.executives_id
                              )
                            "
                            :src="
                              data2.executive_logo
                                ? data2.executive_logo
                                : '/img/male.png'
                            "
                            width="50"
                            height="50"
                            :class="index2 == 0 ? 'sm-img' : 'sm-img minus8'"
                            :style="
                              index2 == 0 ? 'width: 31px;' : 'width: 37px;'
                            "
                          />
                          <img
                            v-if="data2.executive_logo || !data2.gender"
                            style="
                              cursor: pointer;
                              border: 1px solid white; /*! padding: 0.1px; */
                              box-shadow: 18px;
                              box-shadow: 0px 0px 0.5px 0.5px GREY;
                            "
                            :title="data2.executive_name"
                            v-on:click="
                              $router.push(
                                '/executive-detail/' + data2.executives_id
                              )
                            "
                            :src="
                              data2.executive_logo
                                ? data2.executive_logo
                                : '/img/male.png'
                            "
                            width="50"
                            height="50"
                            :class="index2 == 0 ? 'sm-img' : 'sm-img minus8'"
                            :style="
                              index2 == 0 ? 'width: 31px;' : 'width: 37px;'
                            "
                          />
                        </div>
                      </div>
                      <!-- sm-img minus8 -->
                    </div>
                    <div class="col-3 mt-3 row">
                      <div
                        v-for="(data2, index2) in data.deals_invested_company"
                        :key="index2"
                      >
                        <div v-if="index2 <= 4">
                          <img
                            style="
                              cursor: pointer;
                              border: 1px solid white; /*! padding: 0.1px; */
                              box-shadow: 18px;
                              box-shadow: 0px 0px 0.5px 0.5px GREY;
                            "
                            :title="data2.company_name"
                            v-on:click="
                              $router.push(
                                '/company-detail/' + data2.company_id
                              )
                            "
                            :src="data2.company_logo"
                            width="50"
                            height="50"
                            :class="index2 == 0 ? 'sm-img ' : 'sm-img minus8'"
                            :style="
                              index2 == 0 ? 'width: 31px;' : 'width: 37px;'
                            "
                          />
                        </div>
                      </div>
                      <!-- sm-img minus8 -->
                    </div>
                  </div>
                </div>

                <div
                  v-if="data.is_company_logo == 0"
                  v-on:click="openLink(data.news_url)"
                  style="cursor: pointer"
                  class="col-lg-1 col-sm-2 col-6 text-center"
                >
                  <img
                    :title="
                      data.publication && data.publication.name
                        ? data.publication.name
                        : ''
                    "
                    :src="
                      data.publication && data.publication.publication_logo
                        ? data.publication.publication_logo
                        : ''
                    "
                    height="60"
                    width="150"
                  />
                </div>
                <div
                  v-if="data.is_company_logo == 1"
                  v-on:click="openLink(data.news_url)"
                  style="cursor: pointer"
                  class="col-lg-1 col-sm-2 col-6 text-center"
                >
                  <img
                    :title="
                      data.primary_company && data.primary_company.company_name
                        ? data.primary_company.company_name
                        : ''
                    "
                    :src="
                      data.primary_company && data.primary_company.company_logo
                        ? data.primary_company.company_logo
                        : '/img/user-pic.png'
                    "
                    height="60"
                    width="150"
                  />
                </div>
              </div>
              <hr />
            </div>
            <div class="row mx-0 mb-4">
              <div class="col-lg-12 mt-5 text-center">
                <div class="page-bottom-pagination text-right">
                  <Pagination
                    @page-change="pageChange"
                    @items-per-page-change="itemsPerPageChange"
                    ref="listPagination"
                  />
                </div>
              </div>
            </div>
            <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import CountryFlag from "vue-country-flag-next";
import datepicker from "vue3-datepicker";
import Multiselect from "@vueform/multiselect";
import moment from "moment";
// import moment from "moment";
export default {
  name: "Commentaries",
  components: {
    ErrorComponent,
    Pagination,
    datepicker,
    Multiselect,
    CountryFlag,
  },
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      isShowSubsector: false,
      count: 0,
      offset: -1,
      list: [],
      companyList: [],
      industryList: [],
      dealStageList: [],
      dealStageKeyword: "",
      topicList: [],
      countryList: [],
      company_ids: [],
      subsectorList: [],
      regionList: [],
      regionKeyword: "",
      subsectorKeyword: "",
      companyKeyword: "",
      topicKeyword: "",
      company_types: [
        "Public",
        "Private",
        "Education",
        "Government",
        "Not-for-Profit",
      ],
      searchObj: {
        type: "",
        from_date: "",
        to_date: "",
        industry_id: "",
        company_id: "",
        keyword: "",
        topic_id: "",
        deal_stage_id: "",
        subsector_id: "",
        country_id: "",
        company_type: "",
        region_id: "",
      },
      countryKeyword: "",
    };
  },
  mounted() {
    if (this.id) {
      this.searchObj.company_id = this.id;
      this.company_ids.push(this.id);
    }
    this.companylist();
    this.topiclist();
    this.getList(0);
    this.industrylist();
    this.getDealStageList();
    this.getCountryList();
    this.getRegionlist();
  },
  methods: {
    resetFilter() {
      // this.searchObj = {};
      this.searchObj.type = "";
      this.searchObj.keyword = "";
      this.searchObj.from_date = "";
      this.searchObj.to_date = "";
      this.searchObj.industry_id = "";
      this.searchObj.job_function_id_new = "";
      this.searchObj.topic_id = "";
      this.searchObj.country_id = "";
      this.searchObj.company_type = "";
      this.searchObj.deal_stage_id = "";
      this.searchObj.subsector_id = "";
      this.searchObj.region_id = "";
      this.isShowSubsector = false;
      if (!this.id) {
        this.searchObj.company_id = "";
      }

      this.getList(0);
    },
    searchTopicName(e) {
      this.topicKeyword = e;
      this.topiclist();
    },
    geteComponydetail(id) {
      if (id) {
        this.$api
          .getAPI({
            _action: "/company/detail/" + id,
          })

          .then((res) => {
            this.searchObj.industry_id = res.industry_id;
          })
          .catch(() => {});
      }
    },
    onIndustryChange(selectedIndustryId) {
      if (selectedIndustryId) {
        this.subsectorList = [];
        this.isShowSubsector = false;
        this.getSubsectorList(selectedIndustryId);
      } else {
        this.isShowSubsector = false;
      }
    },
    closesub(e) {
      if (!e) {
        this.searchObj.subsector_id = "";
      }
    },
    searchIndustryName(e) {
      this.industryKeyword = e;
      this.industrylist();
    },
    industrylist() {
      this.$api
        .getAPI({
          _action: "/industry/list",
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => {});
    },
    getSubsectorList(id) {
      this.$api
        .getAPI({
          _action: "/subsectors/list/" + id,
        })
        .then((res) => {
          this.subsectorList = res.list;

          // Only show subsector dropdown if there are subsectors(of industry) available
          if (this.subsectorList.length > 0) {
            this.isShowSubsector = true;
          } else {
            this.isShowSubsector = false;
          }
        })
        .catch(() => {
          this.isShowSubsector = false;
        });
    },
    searchDealStage(e) {
      this.dealStageKeyword = e;
      this.getDealStageList();
    },
    getDealStageList() {
      this.$api
        .getAPI({
          _action: "/deal/stage/list",
        })
        .then((res) => {
          this.dealStageList = res;
        })
        .catch(() => {});
    },
    searchCountryName(e) {
      this.countryKeyword = e;
      this.getCountryList();
    },
    getCountryList() {
      var obj = {};
      if (this.countryKeyword) {
        obj.keyword = this.countryKeyword;
      }
      this.$api
        .getAPI({
          _action: "/country/list",
          _body: obj,
        })
        .then((res) => {
          this.countryList = res;
        })
        .catch(() => {});
    },
    searchRegionName(e) {
      this.regionKeyword = e;
      this.getRegionlist();
    },
    getRegionlist() {
      var obj = {};
      if (this.regionKeyword) {
        obj.keyword = this.regionKeyword;
      }
      this.$api
        .getAPI({
          _action: "/region/list",
          _body: obj,
        })
        .then((res) => {
          this.regionList = res;
        })
        .catch(() => {});
    },
    topiclist() {
      var obj = {};
      if (this.topicKeyword) {
        obj.keyword = this.topicKeyword;
      }
      this.$api
        .getAPI({
          _action: "/deals-type/list",
          _body: obj,
        })
        .then((res) => {
          this.topicList = res;
        })
        .catch(() => {});
    },
    searchCompanyName(e) {
      this.companyKeyword = e;
      this.companylist();
    },
    companylist() {
      var obj = {};
      if (this.companyKeyword) {
        obj.keyword = this.companyKeyword;
      }
      if (this.company_ids) {
        obj.company_ids = this.company_ids;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          this.companyList = res;
        })
        .catch(() => {});
    },
    openLink(url) {
      window.open(url);
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      var obj = {};
      obj.offset = this.searchObj.offset;
      if (this.searchObj.from_date) {
        obj.from_date = moment(this.searchObj.from_date).format("DD-MM-YYYY");
      }
      if (this.searchObj.to_date) {
        obj.to_date = moment(this.searchObj.to_date).format("DD-MM-YYYY");
      }

      if (this.searchObj.topic_id) {
        obj.topic_id = this.searchObj.topic_id;
      }

      if (this.searchObj.company_id) {
        obj.company_id = this.searchObj.company_id;
      }
      if (this.searchObj.industry_id) {
        obj.industry_id = this.searchObj.industry_id;
      }
      if (this.searchObj.deal_stage_id) {
        obj.deal_stage_id = this.searchObj.deal_stage_id;
      }
      if (this.searchObj.subsector_id) {
        obj.subsector_id = this.searchObj.subsector_id;
      }
      if (this.searchObj.keyword) {
        obj.keyword = this.searchObj.keyword;
      }
      if (this.searchObj.country_id) {
        obj.country_id = this.searchObj.country_id;
      }
      if (this.searchObj.company_type) {
        obj.company_type = this.searchObj.company_type;
      }
      if (this.searchObj.region_id) {
        obj.region_id = this.searchObj.region_id;
      }
      this.$api
        .getAPI({
          _action: "/deals/pagination/list",
          _body: obj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          this.count = res.count;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No Deals and Transactions list available"
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: #f0f0f0;
  height: 35px;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}
</style>
